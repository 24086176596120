<template>
  <div class="container">
    <v-header></v-header>
    <div class="scroll-container">
      <div class="content">
        <div class="task-list grid">
          <template v-for="(item,index) in cash" v-if="item.minPrice>0">
            <div class="task-item btn" :class="index === active && 'checked'" @click="chooseOne(item, index)">
              <span class="money">¥{{ item.minPrice }}</span>
              <span class="yanz">{{ item.minPrice * 10 }}严值
                <template v-if="item.rate>10">
                  +{{ item.minPrice * (item.rate - 10) }}(送)
                </template>
              </span>
            </div>
          </template>
        </div>

        <div class="money_input">
          <span class="m_i_t">其他充值金额</span>
          <div class="input_box">
            <input type="number" v-model="price" placeholder="¥ 请输入金额(元)" maxlength="5"
                   oninput="value=value.replace(/[^\d.]/g,'')">
          </div>
        </div>
        <div class="record-btn" @click="$router.push('/record?tab=RECHARGE')">查看充值记录<van-icon name="arrow"/></div>

<!--        <div class="money_input" style="margin-top: 8px">-->
<!--          <pay-type-select v-model="payType" ref="payTypeSelect"/>-->
<!--        </div>-->
      </div>


      <div class="pay-btn">
        <a href="javascript: void (0)" class="btn" @click="$refs.payTypeBottomSelect.show()">确认</a>
      </div>

      <div class="recharge-tips">
        <div>①  1元=10严值。</div>
        <div>②  充值后仅限平台内使用，无法重新提现。</div>
        <div>③  支付成功后，严值立即到账。</div>
      </div>

    </div>

    <pay-type-bottom-select ref="payTypeBottomSelect" @choose="submit"/>
  </div>

</template>

<script>
import {rechargeRateV2Api} from "../../api/v2/rechargeRateV2Api";
import PayTypeSelect from "../../components/biz/payTypeSelect";
import {rechargeV2Api} from "../../api/v2/rechargeV2Api";
import {sleep} from "../../config/util";
import PayTypeBottomSelect from "../../components/biz/payTypeBottomSelect";

export default {
  name: "topUpMoney",
  components: {PayTypeBottomSelect, PayTypeSelect},
  data() {
    return {
      cash: [],
      active: 3,
      price: '',
      payType: 'wx'
    }
  },


  watch: {
    price(val) {
      if (val) {
        this.active = null;
      }
    }
  },

  created() {
    this.loadRechargeRates();
  },

  methods: {
    async loadRechargeRates() {
      const res = await rechargeRateV2Api.getList();
      for (const row of res) {
        row.price = row.minPrice;
      }
      this.cash = res;
    },

    chooseOne(item, index) {
      if (this.active === index) {
        this.active = null;
      } else {
        this.active = index;
      }
    },


    async submit({payType,paySubType}) {
      let price = 0;
      if (this.active !== null) {
        price = this.cash[this.active].price;
      } else {
        price = this.price;
      }
      if (!price) return this.$toast.info('请输入价格');
      // this.$router.push(`/pay?price=${(price)}&type=recharge`);
      const res = await rechargeV2Api.startPayRecharge({
        money: price,
        payType,
        paySubType
      })

      // 支付宝支付
      if (paySubType === 21) {
        document.write(res)
      }

      // 微信，浏览器环境
      else if (paySubType === 13) {
        window.location.href = res.mwebUrl + '&redirect_url=https://m.001ppt.com/record?tab=RECHARGE'
      }

      // 微信，内部环境
      else if (paySubType === 11) {
        wx.chooseWXPay({
          timestamp: res.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: res.nonceStr, // 支付签名随机串，不长于 32 位
          package: res.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: res.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: res.paySign, // 支付签名
          success: async (r) => {
            this.$loading('请求中');
            await sleep(1500);
            this.$loading.close();
            this.$toast('充值成功')
            this.$router.push('/record?tab=RECHARGE')
          },
          fail: (e) => {
            self.$toast.error(e.errMsg);
          },
        });
      }
    }
  }
}
</script>

<style scoped lang="less">
.scroll-container {
  display: flex;
  flex-direction: column;
}

.content {
  //flex: 1;
  padding: 15px 28px 40px 28px;
}

.content .task-list {
  display: flex;
  flex-wrap: wrap;
}

.task-list .task-item {
  width: calc((100vw - 56px - 18px) / 2);
  box-sizing: border-box;
  padding: 12px 9px;
  background-color: #fff;
  border: 1px solid #151d36;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
}

.task-item .money {
  font-size: 24px;
  font-weight: bold;
  line-height: 150%;
}

.task-item .yanz {
  color: #cdcfd5;
  font-size: 12px;
  line-height: 150%;
}

.task-item.checked {
  background-color: #151d36;
}

.task-item.checked .money {
  color: #fff;
}

.grid .task-item:nth-child(2n+2) {
  margin-left: 18px;
}

.grid .task-item:nth-child(n+3) {
  margin-top: 18px;
}

.money_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
  padding: 0 50px;
}

.money_input .m_i_t {
  font-size: 12px;
  margin-bottom: 10px;
}

.money_input .input_box {
  display: flex;
  width: 100%;
  background-color: #f3f3f5;
  border-radius: 4px;
  font-size: 14px;
  padding: 15px;
  margin-bottom: 15px;

  input {
    width: 100%;
    border: none;
    outline: none;
    background-color: #f3f3f5;
  }
}

.pay-btn {
  //height: 115px;
  display: flex;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 270px;
    border-radius: 22px;
    background: var(--text-color);
    color: #fff;
    font-size: 16px;
  }
}

.record-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  opacity: 0.3;
  color: rgba(21, 29, 54, 1);
  font-size: 10px;
}

.recharge-tips{
  width: 270px;
  color: rgba(21, 29, 54, 1);
  font-size: 12px;
  text-align: left;
  margin: 20px auto;
}
</style>
