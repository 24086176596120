<template>
  <div v-show="!isWechatEnv">
    <div style="text-align: center;font-size: 12px;padding-bottom: 8px">支付方式</div>
    <div class="pay-type">
      <div class="pay-type-item wx" @click="selectPayType('wx')" :class="{active:value==='wx'}">
        <img v-if="value==='wx'" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-green.svg" alt="">
        <img v-else="value==='wx'" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/wxpay-gray.svg" alt="">
        <div>微信支付</div>
      </div>
      <div class="pay-type-item alipay" @click="selectPayType('alipay')" :class="{active:value==='alipay'}">
        <img v-if="value==='alipay'" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-blue.svg" alt="">
        <img v-else src="https://cdn.001ppt.cn/pc2/static/imgs/svg/alipay-gray.svg" alt="">
        <div>支付宝支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import {unlockMixin} from "../../views/discover/components/unlockMixin";

export default {
  props: ['value'],
  mixins:[unlockMixin],


  methods: {

    selectPayType(payType) {
      this.$emit('input', payType)
    },


    getPayTypeAndPaySubType(){
      if(this.isWechatEnv){
        return {
          payType: 1,
          paySubType: 11
        }
      }
      if(this.value === 'wx'){
        return {
          payType: 1,
          paySubType: 13
        }
      }
      if(this.value === 'alipay'){
        return {
          payType: 2,
          paySubType: 21
        }
      }
    }

  }
}
</script>

<style scoped lang="less">
.pay-type {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-type-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  font-size: 14px;
  width: 100px;
  height: 120px;
  margin: 0 16px;

  img {
    height: 40px;
    width: 40px;
    margin-bottom: 20px;
  }

  &.wx.active {
    color: #7dcb88;
    border-color: #7dcb88;
  }

  &.alipay.active {
    color: #4594d5;
    border-color: #4594d5;
  }
}
</style>
